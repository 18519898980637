.text-field {
  display: block;
  width: 100%;
  background: white;
  color: black;
  line-height: 50px;
  border-radius: 100px;
  text-align: center;
  padding: 0 24px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.text-field::placeholder {
  color: var(--inputPlaceholderText);
}
