.label-text {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $lightGray;
}

.label-text-white {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
}
