.mt-auto {
  margin-top: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.pt-12 {
  padding-top: 48px;
}

.text-center {
  text-align: center;
}

.mt-6 {
  margin-top: 24px;
}

.ml-auto {
  margin-left: auto;
}
