.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 50px;
  gap: 12px;
  border-radius: 30px;
  background: $blue;
  color: $white;
  padding: 0 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  transition: 0.2s background-color;

  &:hover {
    background-color: $blueHover;
  }

  &--has-icon {
    text-align: left;
    justify-content: space-between;
  }

  &--full-width {
    width: 100%;
    display: flex;
  }
}

.scan-buttons {
  margin-top: auto;
  width: 100%;
}
