@font-face {
  font-family: 'Roobert';
  src: url('/assets/Roobert-Regular.woff2') format('woff2'), url('/assets/Roobert-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roobert';
  src: url('/assets/Roobert-SemiBold.woff2') format('woff2'), url('/assets/Roobert-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
