.wrapper {
  max-width: 340px;
  margin: 0 auto;
  min-height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 24px;
}
